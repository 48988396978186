.stacked-image {
    display: flex;
    overflow: hidden;
    transition: 0.5s;
    position: relative;
    transition: 1s;
  }
  
  .item-image {
    flex-shrink: 0;
    width: 100%;
    object-fit: cover;
    height: 250px;
    transition: 0.5s;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .Images-parent {
    flex-shrink: 0;
    width: 100%;
    object-fit: cover;
    height: 250px;
    transition: 0.5s;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .sliderbtn ul {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    top: -100%;
    width: 100%;
    gap: 40px;
    list-style: none;
    z-index: 1;
  }
  
  .sliderbtn ul li {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    cursor: pointer;
    /* border: 2px solid red; */
  }
  
  .sliderbtn ul li .slider-btn {
    position: absolute;
    height: 18px;
    width: 18px;
    border: 2px solid #4E586A;
    mix-blend-mode: difference;
    border-radius: 50%;
    /* right: 10px; */
    top: -30px;
    z-index: 1;
  }
  
  .sliderbtn ul li .slider-btn::before {
    position: absolute;
    content: "";
    height: 10px;
    background-color: #4E586A;
    mix-blend-mode: difference;
    width: 10px;
    border-radius: 50%;
    top: -22%;
    left: -12%;
    margin-left: 4px;
    margin-top: 6px;
  }
  
  